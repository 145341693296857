<app-modal>
  <app-modal-header
    [title]="T.common.add_risk_profile | translate"
    (closed)="closeModal()">
  </app-modal-header>
  <app-modal-body>
    <div class="row">
      <div class="col-12 mb-3">
        <app-filter-dropdown-new
          [label]="(filterTypes.Event | localiseFilterType) + ' *'"
          [filterType]="filterTypes.Event"
          [filterByModuleObjectType]="false"
          [appliedFilters]="filters"
          [typeahead]="true"
          [singleSelect]="true"
          [disableMargin]="true"
          [required]="true"
          (onFilterUpdate)="handleFilterChange($event)">
        </app-filter-dropdown-new>
      </div>
      <div class="col-12 mb-3">
        <app-filter-dropdown-new
          [label]="filterTypes.Owner | localiseFilterType"
          [filterType]="filterTypes.Owner"
          [filterByModuleObjectType]="false"
          [appliedFilters]="filters"
          [typeahead]="true"
          [singleSelect]="true"
          [disableMargin]="true"
          [required]="true"
          (onFilterUpdate)="handleFilterChange($event)">
        </app-filter-dropdown-new>
      </div>
      <div class="col-12 mb-3">
        <app-filter-dropdown-new
          [label]="T.common.confidentiality | translate"
          [filterType]="filterTypes.Risk_Privacy_Status"
          [filterByModuleObjectType]="false"
          [appliedFilters]="filters"
          [typeahead]="true"
          [singleSelect]="true"
          [disableMargin]="true"
          [required]="true"
          (onFilterUpdate)="handleFilterChange($event)">
        </app-filter-dropdown-new>
      </div>
      <div class="col-12 mb-3">
        <app-rich-text-editor-v2
          [inputText]="newRiskProfile.description"
          [showUndoAndRedo]="false"
          [showProfileImage]="false"
          [placeholder]="T.defaultLocalizations.description.one | translate"
          (saved)="updateDescription($event)">
        </app-rich-text-editor-v2>
      </div>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <footer class="footer-wrapper">
      <app-button-rectangle
        class="modal-add"
        [text]="T.common.add | translate"
        [size]="'large'"
        [disabled]="!isRequireFilterAdded"
        [loading]="loading"
        (click)="onAdd()"
      ></app-button-rectangle>
    </footer>
  </app-modal-footer>
</app-modal>
