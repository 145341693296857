import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
export class DashboardInfoWidgetViewModel {
  title: string;
  field1Label: string;
  field1Text: string;
  field2Label: string;
  field2Text: string;
  field3Label: string;
  field3Text: string;

  constructor(translateService: TranslateService) {
    this.title = translateService.instant(T.defaultLocalizations.title.one);
    this.field1Label = translateService.instant(T.common.duty_manager);
    this.field1Text = 'N/A';
    this.field2Label = translateService.instant(T.common.contact);
    this.field2Text = 'N/A';
    this.field3Label = translateService.instant(T.common.key_updates);
    this.field3Text = 'N/A';
  }
}
