import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { CommentsService } from '../../../services/comments.service';
import { Subscription } from 'rxjs';
import { EmployeeViewModel } from '../../../viewModels/employeeViewModel';

@Component({
  selector: 'app-filters-inline-edit-lozenges',
  templateUrl: './filters-inline-edit-lozenges.component.html',
  styleUrls: ['./filters-inline-edit-lozenges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.filters-inline-edit-lozenges-disabled]': 'disabled',
  },
})
export class FiltersInlineEditLozengesComponent implements OnInit, OnDestroy {
  @Input() appliedFilters: FilterViewModel[];
  @Input() label: string;
  @Input() showHeader: boolean = false;
  @Input() filterType: FilterTypes;
  @Input() canClickOnStar: boolean = true;
  @Input() disabled: boolean = false;

  @Output() filterRemoved: EventEmitter<FilterViewModel> = new EventEmitter();
  @Output() makePrimary: EventEmitter<FilterViewModel> = new EventEmitter();

  public readonly T = T;
  public readonly filterTypes = FilterTypes;
  public isPrimaryIconHovered = false;
  /**
   * Used to show avatar icons for filters with FilterType.Owner
   */
  public availableEmployees: EmployeeViewModel[] = [];
  private subscription = new Subscription();

  constructor(private readonly commentsService: CommentsService, private readonly cdr: ChangeDetectorRef) {}


  ngOnInit(): void {
    this.initAvailableEmployees();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get isPrimaryFunctionalityAllowed(): boolean {
    return this.canClickOnStar && this.filterType == FilterTypes.Department;
  }

  onFilterRemove(filter: FilterViewModel): void {
    // this.appliedFilters = this.appliedFilters.filter(f => f.id !== filter.id);
    this.filterRemoved.emit(filter);
  }

  onPrimaryClick(filter: FilterViewModel): void {
    if (filter.isPrimary) {
      filter.isPrimary = false;
    } else {
      filter.isPrimary = true;
    }
    this.makePrimary.emit(filter);
  }

  onClose(): void {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    window.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  private initAvailableEmployees() {
    if(this.filterType === FilterTypes.Owner) {
      this.subscription.add(
        this.commentsService.getAllEmployeesByAccountId().subscribe((res) => {
          this.availableEmployees = res;
          this.cdr.detectChanges();
        })
      );
    }
  }
  public getEmployee(employeeId: number): EmployeeViewModel {
    return this.availableEmployees.find((employee) => employee.id == employeeId);
  }
}
