import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { EventDetailsViewModel } from '../../../viewModels/events/eventDetailsViewModel';
import { Account } from '../../../models/account';
import { AuthenticationService } from '../../../services/authentication.service';
import { TimeZoneService } from '../../../services/timeZone.service';

@Component({
  selector: 'app-filters-live-event-entry',
  templateUrl: './filters-live-event-entry.component.html',
  styleUrls: ['./filters-live-event-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersLiveEventEntryComponent implements OnInit {
  @Input() event: EventDetailsViewModel;
  @Input() selected: boolean;

  @Output() eventSelected = new EventEmitter<EventDetailsViewModel>();

  account: Account;

  constructor(private readonly timeZoneService: TimeZoneService, private readonly authService: AuthenticationService) {}

  ngOnInit() {
    this.account = this.authService.getCurrentAccount();
  }

  get date(): string {
    const { startDate, endDate } = this.event;

    const fromDateLocalized: string = this.timeZoneService.localiseDateISOStringByCustomFormat(startDate, 'MM/DD/YYYY');
    const toDateLocalized: string = this.timeZoneService.localiseDateISOStringByCustomFormat(endDate, 'MM/DD/YYYY');

    // both dates are equal e.g. there is no period, the event happens on a single day
    if (fromDateLocalized === toDateLocalized) {
      const today: Date = new Date();
      const todayLocalized: string = this.timeZoneService.localiseDateISOStringByCustomFormat(today.toISOString(), 'MM/DD/YYYY');

      if (new Date(todayLocalized).getTime() - new Date(fromDateLocalized).getTime() === 24 * 60 * 60 * 1000) {
        return 'Yesterday';
      } else if (new Date(todayLocalized).getTime() - new Date(fromDateLocalized).getTime() === 0) {
        return 'Today';
      } else if (new Date(todayLocalized).getTime() - new Date(fromDateLocalized).getTime() === -(24 * 60 * 60 * 1000)) {
        return 'Tomorrow';
      } else {
        return fromDateLocalized;
      }
    } else {
      return `${fromDateLocalized} to ${toDateLocalized}`;
    }
  }

  @HostListener('click')
  onClick(): void {
    this.eventSelected.emit(this.event);
  }
}
