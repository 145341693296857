export enum sortDropdownOptions {
  A_Z = 1,
  Z_A = 2,
  Created_New_Old = 3,
  Created_Old_New = 4,
  Updated_New_Old = 5,
  Updated_Old_New = 6,
  Severity_Low_High = 7,
  Severity_High_Low = 8,
  RAG_Not_Started_To_Complete = 9,
  RAG_Complete_To_Not_Started = 10,
  High_To_Low = 11,
  Low_To_High = 12,
  Date_New_Old = 13,
  Date_Old_New = 14,
  Status_New_To_Closed = 15,
  Status_Closed_To_New = 16
}
