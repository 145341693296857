import { Injectable, ComponentFactoryResolver, Injector, Type, ComponentRef, ViewContainerRef, ApplicationRef } from '@angular/core';

@Injectable()
export class DynamicComponentsService {
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly injector: Injector,
    private readonly appRef: ApplicationRef
  ) {}

  createComponentElement<T>(
    componentType: Type<T>,
    obj: object,
    attachToAppRef: boolean = false
  ): ComponentRef<T> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const component: ComponentRef<T> = componentFactory.create(this.injector);

    Object.keys(obj).forEach((k) => (component.instance[k] = obj[k]));

    component.changeDetectorRef.detectChanges();

    if(attachToAppRef) {
      this.appRef.attachView(component.hostView);
    }

    return component;
  }

  createComponentElementAt<T>(
    container: ViewContainerRef,
    componentType: Type<T>,
    obj: object, attachToAppRef:
    boolean = true
  ): ComponentRef<T> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const component: ComponentRef<T> = container.createComponent(componentFactory);

    Object.keys(obj).forEach((k) => (component.instance[k] = obj[k]));

    component.changeDetectorRef.detectChanges();

    if(attachToAppRef) {
      this.appRef.attachView(component.hostView);
    }

    return component;
  }
}
