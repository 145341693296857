import { Injectable, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Popup } from '../models/popup';
@Injectable()
export class PopupEventsEmitter {
  private readonly popupChanged = new Subject<Popup>();
  private readonly popupComponentViewInitialized = new Subject<HTMLElement>();
  private readonly popupCancelClicked = new Subject<void>();
  private readonly popupSaveChangesClicked = new Subject<void>();
  private readonly popupDimensionsChanged = new Subject<void>();
  private readonly popupElementRefChanged = new Subject<HTMLElement>();
  private readonly popupPositionChanged = new Subject<void>();
  private readonly popupContentChanged = new Subject<any>();

  popupChanged$ = this.popupChanged.asObservable();
  popupComponentViewInitialized$ = this.popupComponentViewInitialized.asObservable();
  popupCancelClicked$ = this.popupCancelClicked.asObservable();
  popupSaveChangesClicked$ = this.popupSaveChangesClicked.asObservable();
  popupDimensionsChanged$ = this.popupDimensionsChanged.asObservable();
  popupElementRefChanged$ = this.popupElementRefChanged.asObservable();
  popupPositionChanged$ = this.popupPositionChanged.asObservable();
  popupContentChanged$ = this.popupContentChanged.asObservable();

  popupElement: HTMLElement;
  directiveElementRef: ElementRef<HTMLElement>;

  broadcastPopupChanged(popup: Popup) {
    this.popupChanged.next(popup);
  }

  broadcastPopupComponentViewInitialized(element: HTMLElement) {
    this.popupComponentViewInitialized.next((this.popupElement = element));
  }

  broadcastPopupCancelClicked() {
    this.popupCancelClicked.next();
  }

  broadcastPopupSaveChangesClicked() {
    this.popupSaveChangesClicked.next();
  }

  broadcastPopupDimensionsChanged() {
    this.popupDimensionsChanged.next();
  }

  broadcastPopupElementRefChanged(element: HTMLElement) {
    this.popupElementRefChanged.next(element);
  }

  broadcastPopupPositionChanged() {
    this.popupPositionChanged.next();
  }

  broadcastPopupContentChanged(newContent: any) {
    this.popupContentChanged.next(newContent);
  }
}
