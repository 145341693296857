<div class="card" [ngClass]="{'selected-card': isTheCardSelected}">
  @if(objectType === objectTypes.Risk){
    <div class="card-body">
      <h5 class="card-title d-flex align-items-center">
        <span class="material-icons me-2">warning</span>
        <span class="d-inline-block text-truncate"> {{ suggestedItem.title }} </span>
      </h5>
      <div class="d-flex align-items-center justify-content-between mb-3">
          <app-details-dropdown
            [filterType]="filterTypes.Risk_Likelihood"
            [canEdit]="false"
            [canEditMessage]="''"
            [statusBars]="true"
            [appliedFilters]="suggestedItem?.filters">
          </app-details-dropdown>

          <app-details-dropdown
            [filterType]="filterTypes.Risk_Impact"
            [canEdit]="false"
            [canEditMessage]="''"
            [statusBars]="true"
            [appliedFilters]="suggestedItem?.filters">
          </app-details-dropdown>
      </div>
      <div *ngIf="showSelectButton" class="card-btn" (click)="onCardSelected($event)">{{ isTheCardSelected ? (T.common.remove_risk | translate) : (T.common.use_risk | translate) }}</div>
    </div>
  } @else if (objectType === objectTypes.Indicator) {
  <div class="card-body">
    <h5 class="card-title d-flex align-items-center">
      <span class="material-icons me-2" [ngClass]="iconColor">{{ icon }}</span>
      <span class="d-inline-block text-truncate"> {{ suggestedItem.title }} </span>
    </h5>
    <div class="d-flex align-items-center justify-content-between">
      <app-details-dropdown
        [ngClass]="{ 'truncate-category-text': suggestedItem?.sustainabilityScope > 0 }"
        [filterType]="filterTypes.Indicator_Category"
        [canEdit]="false"
        [canEditMessage]="''"
        [label]="'Category'"
        [statusBars]="false"
        [appliedFilters]="filters"
      >
      </app-details-dropdown>
      <ng-container *ngIf="suggestedItem.sustainabilityScope > 0">
        <app-details-dropdown
          class="w-35"
          [filterType]="filterTypes.Sustainability_Scope"
          [canEdit]="false"
          [canEditMessage]="''"
          [label]="'Scope'"
          [statusBars]="false"
          [appliedFilters]="filters"
        >
        </app-details-dropdown>
      </ng-container>
    </div>

    <div class="use-item-bottom">
      <div class="card-btn">Use Indicator</div>
      <div class="card-btn">
        <app-button-transparent
          [app-tooltip]="{
            message: 'Click on indicator to add to your WeTrack account'
          }"
          size="small"
          iconFontSize="18"
          icon="error_outline"
          class="ml-5"
        ></app-button-transparent>
      </div>
    </div>

  </div>
  }
</div>
