import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { IncidentChannelViewModel } from 'src/app/modules/settings/viewModels/incidentChannelsViewModel';
import { T } from 'src/assets/i18n/translation-keys';
import { EventDetailsViewModel } from '../../../viewModels/events/eventDetailsViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FixedEventFiltersService } from '../../../services/fixed-event-filters.service';
import { FixedIncidentChannelFiltersService } from '../../../services/fixed-incident-channel-filters.service';
import { FixedZoneFiltersService } from '../../../services/fixed-zone-filters.service';
import { LocalisationService } from '../../../services/localisation.service';
import { FixedFilterType } from '../../../types/FixedFilterType';
import { ZoneViewModel } from '../../../viewModels/zoneViewModel';

@Component({
  selector: 'app-fixed-filters',
  templateUrl: './fixed-filters.component.html',
  styleUrls: ['./fixed-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.filters-events-mobile]': 'mobile',
  },
})
export class FixedFiltersComponent implements OnInit, OnChanges {
  @Input() filterType: FixedFilterType;
  @Input() mobile: boolean;
  @Input() filters: FilterViewModel[];
  @Input() appliedFilters: FilterViewModel[];

  @Output() onFiltersUpdated = new EventEmitter<FilterViewModel[]>();

  private readonly subscriptions = new Subscription();
  searchValue: string;
  events: EventDetailsViewModel[] = [];
  zones: ZoneViewModel[] = [];
  channels: IncidentChannelViewModel[] = [];
  filteredEvents: EventDetailsViewModel[] = [];
  filteredZones: ZoneViewModel[] = [];
  filteredChannels: IncidentChannelViewModel[] = [];
  eventFilters: FilterViewModel[] = [];
  zoneFilters: FilterViewModel[] = [];
  channelFilters: FilterViewModel[] = [];
  selectedFilters: FilterViewModel[] = [];
  clearAllFilters: FilterViewModel[] = [];
  filterTypes = FilterTypes;
  clearAllButtonLabel = 'Clear All';
  private localisedEvents = 'Events';
  private localisedZones = 'Zones';
  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly fixedZoneFiltersService: FixedZoneFiltersService,
    private readonly fixedEventFiltersService: FixedEventFiltersService,
    private readonly fixedIncidentChannelFiltersService: FixedIncidentChannelFiltersService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.localisedEvents = this.localisationService.localise(this.localisedEvents);
    this.localisedZones = this.localisationService.localise(this.localisedZones);

    this.subscriptions.add(
      combineLatest([this.fixedEventFiltersService.allowedFilters$, this.fixedEventFiltersService.allowedEntities$]).subscribe(
        ([allowedFilters, allowedEvents]) => {
          this.eventFilters = allowedFilters;
          this.events = allowedEvents;
          this.filteredEvents = JSON.parse(JSON.stringify(this.events.filter((event) => event.id.toString() !== '0')));

          this.changeDetectorRef.markForCheck();
        }
      )
    );

    this.subscriptions.add(
      combineLatest([this.fixedZoneFiltersService.allowedFilters$, this.fixedZoneFiltersService.allowedEntities$]).subscribe(
        ([allowedFilters, allowedZones]) => {
          this.zoneFilters = allowedFilters;
          this.zones = allowedZones;
          this.filteredZones = JSON.parse(JSON.stringify(this.zones.filter((zone) => zone.id.toString() !== '0')));

          this.changeDetectorRef.markForCheck();
        }
      )
    );

    this.subscriptions.add(
      combineLatest([
        this.fixedIncidentChannelFiltersService.allowedFilters$,
        this.fixedIncidentChannelFiltersService.allowedEntities$,
      ]).subscribe(([allowedFilters, allowedChannels]) => {
        this.channelFilters = allowedFilters;
        this.channels = allowedChannels;
        this.filteredChannels = JSON.parse(JSON.stringify(this.channels.filter((channel) => channel.id.toString() !== '0')));

        this.changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appliedFilters) {
      this.selectedFilters = JSON.parse(JSON.stringify(this.appliedFilters));
    }

    if (this.filterType === FilterTypes.Event) {
      this.clearAllButtonLabel = this.translateService.instant(T.common.show_all_items, { items: this.localisedEvents });
      this.clearAllFilters = [this.fixedEventFiltersService.allFilter];
    } else if (this.filterType === FilterTypes.Zone) {
      this.clearAllButtonLabel = this.translateService.instant(T.common.show_all_items, { items: this.localisedZones });
      this.clearAllFilters = [this.fixedZoneFiltersService.allFilter];
    } else if (this.filterType === FilterTypes.Incident_Channel) {
      this.clearAllButtonLabel = this.translateService.instant(T.common.show_all_channels);
      this.clearAllFilters = [this.fixedIncidentChannelFiltersService.allFilter];
    } else {
      this.clearAllButtonLabel = this.translateService.instant(T.common.clear_all);
      this.clearAllFilters = [];
    }
  }

  get isFilterUpdated(): boolean {
    return JSON.stringify(this.appliedFilters) === JSON.stringify(this.selectedFilters);
  }

  get isClearDisabled(): boolean {
    return this.appliedFilters[0].filterValue.toString() === '0';
  }

  onEntryEventSelected(event: EventDetailsViewModel): void {
    if (event.id.toString() !== this.selectedFilters[0]?.filterValue.toString()) {
      this.selectedFilters = this.eventFilters.filter((f) => f.filterValue.toString() === event.id.toString());
    } else {
      this.selectedFilters = this.clearAllFilters;
    }
  }

  onEntryZoneSelected(zone: ZoneViewModel): void {
    if (zone.id.toString() !== this.selectedFilters[0]?.filterValue.toString()) {
      this.selectedFilters = this.zoneFilters.filter((f) => f.filterValue.toString() === zone.id.toString());
    } else {
      this.selectedFilters = this.clearAllFilters;
    }
  }

  onEntryChannelSelected(channel: IncidentChannelViewModel): void {
    if (channel.id.toString() !== this.selectedFilters[0]?.filterValue.toString()) {
      this.selectedFilters = this.channelFilters.filter((f) => f.filterValue.toString() === channel.id.toString());
    } else {
      this.selectedFilters = this.clearAllFilters;
    }
  }

  onSearch(value: string): void {
    this.searchValue = value.toLowerCase().trim();

    if (this.filterType === FilterTypes.Event) {
      if (value) {
        this.filteredEvents = this.events.filter(
          (f) => f.title.toLowerCase().indexOf(this.searchValue) > -1 && f.id.toString() !== '0'
        );
      } else {
        this.filteredEvents = this.events.filter((f) => f.id.toString() !== '0');
      }
    } else if (this.filterType === FilterTypes.Zone) {
      if (value) {
        this.filteredZones = this.zones.filter(
          (f) => f.title.toLowerCase().indexOf(this.searchValue) > -1 && f.id.toString() !== '0'
        );
      } else {
        this.filteredZones = this.zones.filter((f) => f.id.toString() !== '0');
      }
    } else if (this.filterType === FilterTypes.Incident_Channel) {
      if (value) {
        this.filteredChannels = this.channels.filter(
          (f) => f.title.toLowerCase().indexOf(this.searchValue) > -1 && f.id.toString() !== '0'
        );
      } else {
        this.filteredChannels = this.channels.filter((f) => f.id.toString() !== '0');
      }
    }

    this.changeDetectorRef.markForCheck();
  }

  onClearAll(): void {
    this.onFiltersUpdated.next(this.clearAllFilters);
  }

  onCancel(): void {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    window.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  onApply(): void {
    this.onFiltersUpdated.next(this.selectedFilters);
    this.onCancel();
  }

  isEventSelected(event: EventDetailsViewModel): boolean {
    return this.selectedFilters[0]?.filterValue.toString() === event.id.toString();
  }

  isZoneSelected(zone: ZoneViewModel): boolean {
    return this.selectedFilters[0]?.filterValue.toString() === zone.id.toString();
  }

  isChannelSelected(channel: IncidentChannelViewModel): boolean {
    return this.selectedFilters[0]?.filterValue.toString() === channel.id.toString();
  }
}
