import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, map, Subject } from 'rxjs';
import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { ImageExtensions } from '../enums/imageExtensions';
import { ObjectTypes } from '../enums/objectTypes';
import { LocalisationService } from './localisation.service';
import { ModuleViewModel } from '../viewModels/ModuleViewModel';
import { AuthenticationService } from './authentication.service';
import { Account } from '../models/account';
import { HeaderFilterTypes } from '../enums/headerFilterTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeUtil } from '../utilities/employee.utilities';

@Injectable()
export class ModuleService {
  private _modules: ModuleViewModel[] = [];
  private _currentModule: ModuleTypes = ModuleTypes.Planning;
  private _currentObjectTypes: ObjectTypes[] = [];
  private _currentHeaderFilterType: HeaderFilterTypes;

  private modules: ModuleViewModel[] = [];
  private accountModules: ModuleViewModel[] = [];
  private readonly moduleChanged: Subject<ModuleTypes> = new Subject();
  readonly moduleChanged$ = this.moduleChanged.asObservable();
  private readonly objectTypesChanged: Subject<ObjectTypes[]> = new Subject();
  readonly objectTypesChanged$ = this.objectTypesChanged.asObservable();
  private readonly headerFilterTypeChanged: Subject<HeaderFilterTypes> = new Subject();
  readonly headerFilterTypeChanged$ = this.headerFilterTypeChanged.asObservable();
  private account: Account;

  constructor(
    private readonly router: Router,
    private readonly localisationService: LocalisationService,
    private readonly authService: AuthenticationService,
    private readonly translateService: TranslateService
  ) {
    this.account = this.authService.getCurrentAccount();
    this.initModuleItems();

    if (this.account) {
      this.initAccountModules();
    }
  }

  get routerUrl(): string {
    return this.router.url.toLowerCase();
  }

  get currentModule(): ModuleTypes {
    return this._currentModule;
  }

  get isAccountHubModule(): boolean {
    return this.currentModule === ModuleTypes.AccountHub;
  }

  get isRunsheetModule(): boolean {
    return this.currentModule === ModuleTypes.Runsheets;
  }

  get isVenuesModule(): boolean {
    return this.currentModule === ModuleTypes.Planning;
  }

  get isIncidentsModule(): boolean {
    return this.currentModule === ModuleTypes.Incidents;
  }

  get isMyTrackModule(): boolean {
    return this.currentModule === ModuleTypes.My_Track;
  }

  get isSettingsRoute(): boolean {
    return this.routerUrl.startsWith('/v2/settings');
  }

  get currentObjectTypes(): ObjectTypes[] {
    return this._currentObjectTypes;
  }

  get currentHeaderFilterType(): HeaderFilterTypes {
    return this._currentHeaderFilterType;
  }

  initModuleItems() {
    this._modules.push({
      moduleType: ModuleTypes.Planning,
      name: 'Planning',
      description: 'Projects, Tasks and Risks',
      defaultRoute: '/v2/mytrack/planning',
      icon: 'wetrack_icon_projects',
      objectTypes: [
        ObjectTypes.Project,
        ObjectTypes.Task
      ],
    });

    this._modules.push({
      moduleType: ModuleTypes.Risk,
      name: 'Risks',
      description: 'Risks',
      defaultRoute: '/v2/risk/risks-issues',
      icon: 'wetrack_icon_projects',
      objectTypes: [
        ObjectTypes.Risk,
        ObjectTypes.Risk_Impact_Type,
        ObjectTypes.Risk_Action_Item,
        ObjectTypes.Risk_Assesment,
        ObjectTypes.Risk_Profile,
      ],
    });

    this._modules.push({
      moduleType: ModuleTypes.Incidents,
      name: 'Control',
      description: 'Incidents, Logs, Jobs and PEAPs',
      defaultRoute: '/v2/control/dashboard',
      icon: 'wetrack_icon_incidents',
      objectTypes: [ObjectTypes.IncidentItem, ObjectTypes.Job, ObjectTypes.Public_Incident_Report],
    });

    this._modules.push({
      moduleType: ModuleTypes.AccountHub,
      name: 'Account Hub',
      description: 'Parent Child Accounts',
      defaultRoute: '/v2/accounthub/list',
      icon: 'wetrack_icon_run-sheets',
      objectTypes: [ObjectTypes.Global],
    });

    this._modules.push({
      moduleType: ModuleTypes.Runsheets,
      name: 'Run Sheets',
      description: 'Run Sheets and Daily Schedules',
      defaultRoute: '/v2/runsheets/list',
      icon: 'wetrack_icon_run-sheets',
      objectTypes: [ObjectTypes.Runsheet, ObjectTypes.Runsheet_Item],
    });

    this._modules.push({
      moduleType: ModuleTypes.Sustainability,
      name: 'Sustainability',
      description: 'Tracking Frameworks and Indicators',
      defaultRoute: '/v2/sustainability/indicators',
      icon: 'wetrack_icon_run-sheets',
      objectTypes: [ObjectTypes.Indicator,ObjectTypes.Indicator_Target, ObjectTypes.Indicator_Update],
    });

    this._modules.push({
      moduleType: ModuleTypes.General,
      name: 'General',
      description: 'General Reporting',
      defaultRoute: '',
      icon: 'wetrack_icon_run-sheets',
      objectTypes: [ObjectTypes.Employee],
    });

    this.modules = this._modules;
  }

  initAccountModules() {
    this.account = this.authService.getCurrentAccount();
    const employee = this.authService.getCurrentEmployee();
    const isAdmin = employee?EmployeeUtil.IsAdmin(employee):false;

    const accountModuleTypes: ModuleTypes[] = [];
    if (this.account.usePlanning) {
      accountModuleTypes.push(ModuleTypes.Planning);
    }
    if (this.account.useIMS) {
      accountModuleTypes.push(ModuleTypes.Incidents);
    }
    if (this.account.isHubAccount) {
      accountModuleTypes.push(ModuleTypes.AccountHub);
    }
    if (this.account.useRunSheets) {
      accountModuleTypes.push(ModuleTypes.Runsheets);
    }
    if (this.account.useSustainability) {
      accountModuleTypes.push(ModuleTypes.Sustainability);
    }

    if(isAdmin){
      accountModuleTypes.push(ModuleTypes.General);
    }

    if(this.account.showRiskAndIssues) {
      accountModuleTypes.push(ModuleTypes.Risk);
    }

    this.accountModules = this.modules.filter((f) => accountModuleTypes.indexOf(f.moduleType) >= 0);
    this.accountModules.forEach((m) => {
      m.description = this.localisationService.localise(m.description);
    });
  }

  initModuleChangeUponNavigation() {
    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        map((event) => event as RoutesRecognized),
        map((event) => event.state.root.firstChild),
        map((activatedRouteSnapshot) => activatedRouteSnapshot)
      )
      .subscribe(({ data: { moduleType }, firstChild }) => {
        if (moduleType === ModuleTypes.Settings) {
          let child = firstChild;

          while (child.firstChild) {
            child = child.firstChild;
          }

          if (child.data.moduleType !== undefined) {
            this._currentModule = child.data.moduleType as ModuleTypes;
            this.moduleChanged.next(this.currentModule);
          }

          return;
        }

        if (this.currentModule !== moduleType) {
          this._currentModule = moduleType as ModuleTypes;
          this.moduleChanged.next(this.currentModule);
        }
      });
  }

  initChangesUponNavigation() {
    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        map((event) => event as RoutesRecognized),
        map((event) => event.state.root.firstChild),
        map((activatedRouteSnapshot) => {
          while (activatedRouteSnapshot.firstChild) {
            activatedRouteSnapshot = activatedRouteSnapshot.firstChild;
          }

          return activatedRouteSnapshot;
        }),
        map((activatedRouteSnapshot) => activatedRouteSnapshot.data)
      )
      .subscribe(({ objectTypes, headerFilterType }: { objectTypes: ObjectTypes[]; headerFilterType: HeaderFilterTypes }) => {
        this._currentHeaderFilterType = headerFilterType;
        this.headerFilterTypeChanged.next(headerFilterType ?? HeaderFilterTypes.None);

        this._currentObjectTypes = objectTypes?.length ? objectTypes : [];
        this.objectTypesChanged.next(this._currentObjectTypes);
      });
  }

  getModuleName(module: ModuleTypes): string {
    return this._modules.find((x) => x.moduleType === module).name;
  }

  /**
   * Returns translated module name for the given module type
   */
  getModuleTranslatedName(module: ModuleTypes): string {
    switch (module) {
      case ModuleTypes.Planning:
        return this.translateService.instant(T.common.planning);
      case ModuleTypes.Incidents:
        return this.translateService.instant(T.common.control);
      case ModuleTypes.Runsheets:
        return this.translateService.instant(T.common.run_sheets);
      case ModuleTypes.AccountHub:
        return this.translateService.instant(T.common.account_hub);
      case ModuleTypes.Sustainability:
        return this.translateService.instant(T.common.sustainability);
      case ModuleTypes.General:
          return this.translateService.instant(T.common.general);
      case ModuleTypes.Risk:
          return this.translateService.instant(T.common.risk);

    }
  }

  getModuleDefaultRoute(module: ModuleTypes): string {
    return this._modules.find((x) => x.moduleType === module).defaultRoute;
  }

  getModuleIcon(module: ModuleTypes, extension: ImageExtensions): string {
    const string = ImageExtensions.items().find((x) => x.key === extension);
    const extensionString = string !== null ? string.value : 'png';
    return this._modules.find((x) => x.moduleType === module).icon + '.' + extensionString;
  }

  getModuleDescription(module: ModuleTypes): string {
    return this._modules.find((x) => x.moduleType === module).description;
  }

  getReportingModules(): ModuleViewModel[] {
    return this.accountModules.filter(
      (r) =>
        r.moduleType === ModuleTypes.Planning ||
        r.moduleType === ModuleTypes.Incidents ||
        r.moduleType === ModuleTypes.Runsheets ||
        r.moduleType === ModuleTypes.AccountHub ||
        r.moduleType === ModuleTypes.Sustainability ||
        r.moduleType === ModuleTypes.General ||
        r.moduleType === ModuleTypes.Risk
    );
  }

  getObjectTypes(moduleType: ModuleTypes): ObjectTypes[] {
    return this.modules
      .filter((s) => s.moduleType === moduleType)
      .map((d) => d.objectTypes)
      .reduce((acc, val) => acc.concat(val), []);
  }
}
