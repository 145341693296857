import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  Data,
  RoutesRecognized,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
} from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { defer, forkJoin, fromEvent, iif } from 'rxjs';
import { Configuration } from './app.constants';
import { EmployeeSettingsService } from './modules/shared/services/employee-settings.service';
import { SignalRService } from './modules/shared/services/signalr.service';
import { Employee } from './modules/shared/models/employee';
import { AuthenticationService } from './modules/shared/services/authentication.service';
import { CachingService } from './modules/shared/services/caching.service';
import { GanttEventsEmitter } from './modules/shared/events/gantt.events';
import { HeaderEventsEmitter } from './modules/shared/events/header.events';
import { WindowEventsEmitter } from './modules/shared/events/window.events';
import { ConfigurationService } from './modules/shared/services/configuration.service';
import { AuthenticationManager } from './modules/shared/managers/authenticationManager';
import { LoginEventsEmitter } from './modules/shared/events/login.events';
import { DropdownEventsEmitter } from './modules/shared/events/dropdown.events';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from './modules/auth/auth.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { Account } from './modules/shared/models/account';
import { capitalize } from './modules/shared/utilities/string.utilities';
import { OnboardingModalComponent } from './modules/settings/components/common/modals/onboarding/onboarding-modal.component';
import { ModuleService } from './modules/shared/services/module.service';
import { BsModalConfig } from './modules/shared/config/modal.config';
import { environment } from 'src/environments/environment';
import { RiskRAGMatrixService } from './modules/shared/services/risk-rag-matrix.service';
import { SortingService } from './modules/shared/services/sorting.service';
import { NotificationsService } from './modules/shared/services/notifications.service';
import { HeaderBarComponent } from './modules/shared/components/common/app/header-bar/header-bar.component';
import { AllowedFiltersService } from './modules/shared/services/allowed-filters.service';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { TimeZoneService } from './modules/shared/services/timeZone.service';
import { LocalisationService } from './modules/shared/services/localisation.service';
import { EmployeeRoleTypes } from './modules/shared/enums/employees/EmployeeRoleTypes';
import { EmployeeUtil } from './modules/shared/utilities/employee.utilities';
import { Capacitor } from '@capacitor/core';
import { PushNotificationService } from './modules/shared/services/push-notifications.service';
import { PlanningSettingsService } from './modules/settings/services/planning-settings.service';
import { UtilitiesService } from './modules/shared/services/utilities.service';
import { AlertController, IonMenu } from '@ionic/angular';
import { MobileVersionUpdateService } from './modules/shared/services/mobile-version-update.service';
import { Network } from '@capacitor/network';
import { ComponentEventEmitter } from './modules/shared/events/component.events';
import { HeaderFiltersCachingService } from './modules/shared/services/header-filters-caching.service';
import { GenerateAccountFiltersService } from './modules/shared/services/generate-account-filters.service';
import { FixedHeaderFiltersService } from './modules/shared/services/fixed-header-filters.service';
import { WtTranslationService } from './modules/wt-translation/wt-translation.service';
import { WtStorageService } from './modules/shared/services/wt-storage.service';
import * as IntercomMobile from '@capacitor-community/intercom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { EnumUtilities } from './modules/shared/utilities/enum.utilities';
import { TrackingService } from './modules/shared/services/tracking.service';
import { NavigationService } from './modules/shared/services/navigation.service';
import { Intercom, IntercomBootInput } from '@supy-io/ngx-intercom';
import { PendoService } from './modules/shared/services/pendo.service';
import { UserAgentService } from './modules/shared/services/user-agent.service';

declare let pendo: any;

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [EmployeeSettingsService],
})
export class AppComponent implements OnInit {
  private endPageTitle: string = 'WeTrack';
  private defaultPageTitle: string = 'Home';

  @ViewChild('wrapper') wrapper: ElementRef<HTMLElement>;
  @ViewChild('navbar') navBar: HeaderBarComponent;
  @ViewChild('sidebar', { read: ElementRef }) sideBar: ElementRef<HTMLElement>;
  @ViewChild('routeContainer') routeContainer: ElementRef<HTMLElement>;
  @ViewChild('ionMenu', { read: IonMenu }) ionMenu: IonMenu;

  currentAccount: Account;
  currentEmployee: Employee;
  currentVersion: unknown;
  loading: boolean = true;
  isDevMode: boolean;
  bsModalRef: BsModalRef;
  hasBeenInitialized: boolean = false;
  sidebarIsCollapsed: boolean;
  public isNativeDevice: boolean = false;
  public isSidebarToggled = false;
  public isGanttFullscreen = false;
  public hasInternetConnection: boolean = true;
  public maxPageRequestedByComponent: boolean = false;
  public maxHeightRequestedByComponent: boolean = false;
  private mobileConnectionAlert: HTMLIonAlertElement;
  isNotUnauthorizedPage = true;

  constructor(
    private readonly pendoService: PendoService,
    public readonly meta: Meta,
    public readonly router: Router,
    public readonly route: ActivatedRoute,
    public readonly title: Title,
    public readonly authenticationService: AuthenticationService,
    public readonly allowedFiltersService: AllowedFiltersService,
    public readonly generateAccountFiltersService: GenerateAccountFiltersService,
    public readonly cachingService: CachingService,
    private readonly headerFiltersCachingService: HeaderFiltersCachingService,
    private readonly fixedHeaderFiltersService: FixedHeaderFiltersService,
    public readonly ganttEventsEmitter: GanttEventsEmitter,
    public readonly headerEventsEmitter: HeaderEventsEmitter,
    public readonly windowEventsEmitter: WindowEventsEmitter,
    public readonly baseConfigurationService: ConfigurationService,
    public readonly configurations: Configuration,
    public readonly authenticationManager: AuthenticationManager,
    public readonly loginEventEmitter: LoginEventsEmitter,
    public readonly changeDetectorRef: ChangeDetectorRef,
    public readonly dropdownEventsEmitter: DropdownEventsEmitter,
    public readonly bsModalService: BsModalService,
    private readonly authService: AuthService,
    private readonly employeeSettingService: EmployeeSettingsService,
    private readonly signalRService: SignalRService,
    private readonly modalService: BsModalService,
    private readonly moduleService: ModuleService,
    private readonly bsModalConfig: BsModalConfig,
    private readonly riskRAGMatrixService: RiskRAGMatrixService,
    private readonly sortingService: SortingService,
    private readonly notificationsService: NotificationsService,
    private readonly timeZoneService: TimeZoneService,
    private readonly localisationService: LocalisationService,
    private readonly pushNotifications: PushNotificationService,
    private readonly planningSettingsService: PlanningSettingsService,
    private readonly utilitiesService: UtilitiesService,
    private readonly mobileVersionUpdateService: MobileVersionUpdateService,
    private readonly alertCtrl: AlertController,
    private readonly componentEventEmitter: ComponentEventEmitter,
    private readonly wtTranslationService: WtTranslationService,
    private readonly wtStorage: WtStorageService,
    private zone: NgZone,
    public readonly intercom: Intercom,
    private readonly trackingService: TrackingService,
    private readonly navigationService: NavigationService,
    private readonly userAgentService: UserAgentService
  ) {
    this.currentVersion = require('../../package.json');

    localStorage.setItem('id_token_expires_at', '1');

    this.isDevMode = this.configurations.mode === 'Dev';
    this.subscribeDeeplinking();
  }

  async ngOnInit() {
    this.trackingService.setStartingTime();
    this.isNativeDevice = Capacitor.getPlatform() !== 'web';
    if (this.isNativeDevice) {
      this.hasInternetConnection = (await Network.getStatus()).connected;
      if (!this.hasInternetConnection) {
        void this.showConnectivityAlert();
        return;
      }
      this.listenMobileDeviceInternetStatusChange();
    }
    void this.initApplication();
    // Handle Android back button click
    void App.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) {
        void App.exitApp();
      } else {
        window.history.back();
      }
    });
  }

  initApplication() {
    this.authService.init();
    this.initTitleChangeUponNavigation();
    this.moduleService.initModuleChangeUponNavigation();
    this.moduleService.initChangesUponNavigation();

    this.setIosInputFieldFontSize();
    window['GenerateAccountFilters'] = () => {
      this.generateAccountFiltersService.generateFiltersForAccount(this.currentAccount.id).subscribe();
    };

    this.headerEventsEmitter.menuToggled$.subscribe((toggled) => {
      this.sidebarIsCollapsed = !toggled;
      this.changeDetectorRef.detectChanges();
    });

    if (environment.enableExtarnalAPIs === 'true') {
      const cdnjsCloudflare = document.createElement('script');
      cdnjsCloudflare.src = 'https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.5.0-beta4/html2canvas.js';
      cdnjsCloudflare.integrity = 'sha384-meJ7klOah5myLGpSAyCt2zgXVfW0Q8pMimjCDcOhLVB6tO54ExwPpvEjWa4SyI0W';
      cdnjsCloudflare.crossOrigin = 'anonymous';
      document.getElementsByTagName('body')[0].appendChild(cdnjsCloudflare);

      const cdnjsCloudflareSvg = document.createElement('script');
      cdnjsCloudflareSvg.src = 'https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.5.0-beta4/html2canvas.svg.js';
      cdnjsCloudflare.integrity = 'sha384-oxNu1RpmXl8caijodD85XsjrvbfsyCPu44ki1aDbngud9AtF6ROdRyibIlvVvTTa';
      cdnjsCloudflare.crossOrigin = 'anonymous';
      document.getElementsByTagName('body')[0].appendChild(cdnjsCloudflareSvg);
    }

    this.ganttEventsEmitter.ganttFullscreenToggled$.pipe(filter(() => !!this.sideBar)).subscribe((ganttFullscreenToggled) => {
      if (ganttFullscreenToggled) {
        // this.sideBar.nativeElement.classList.add("gantt-full-screen");
        this.isGanttFullscreen = true;
        this.navBar.setSidebarToggleButtonHidden(true);
      } else {
        // this.sideBar.nativeElement.classList.remove("gantt-full-screen");
        this.isGanttFullscreen = false;
        this.navBar.setSidebarToggleButtonHidden(false);
      }
    });

    this.bsModalService.onShown.subscribe(() => {
      const modal = document.querySelector('.modal');

      modal.addEventListener('scroll', () => this.dropdownEventsEmitter.broadcastDropdownPositionChanged());
    });

    let asyncLoadCount = 0;

    this.navigationService.init();
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof RouteConfigLoadStart || event instanceof RouteConfigLoadEnd || event instanceof NavigationEnd
        )
      )
      .subscribe((event): void => {
        if (event instanceof RouteConfigLoadStart) {
          asyncLoadCount++;
        } else if (event instanceof RouteConfigLoadEnd) {
          asyncLoadCount--;
        } else if (event instanceof NavigationEnd) {
          if (event.url.indexOf('unauthorized') === -1) {
            this.isNotUnauthorizedPage = true;
          }
          if (event.url.indexOf('unauthorized') > -1 || event.url.indexOf('no-access') > -1) {
            this.loading = false;
            this.isNotUnauthorizedPage = false;
          } else if (event.url.indexOf('/v2/devtools') > -1) {
            this.loading = false;
          }
        }

        if (this.hasBeenInitialized) {
          if (this.isNativeDevice) {
            this.loading = false;
          } else {
            this.loading = !!asyncLoadCount;
          }
        }
      });
    this.authService.isAuthenticated$.pipe(filter(Boolean)).pipe(take(1)).subscribe(() => this.initialize());

    if (this.router.url.indexOf('unauthorized') > -1 || this.router.url.indexOf('no-access') > -1) {
      this.loading = false;
    }

    if (this.router.url.indexOf('devtools') > -1) {
      this.loading = false;
    }

    this.trackingService.init();
  }

  private subscribeDeeplinking() {
    void App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = environment.DOMAIN;
        const pathArray = event.url.split(domain);
        const appPath = pathArray.pop();
        if (appPath && pathArray.length > 0) {
          void this.router.navigateByUrl(appPath);
        }
      });
    });
  }

  initIonMenuSubscriptions() {
    this.utilitiesService.sidebarToggled$.subscribe((isToggled) => {
      this.isSidebarToggled = isToggled;
      if (isToggled) {
        void this.ionMenu.open();
      } else {
        void this.ionMenu.close();
      }
    });
  }

  get isGanttRoute(): boolean {
    return this.router.url.startsWith('/v2/gantt');
  }

  get isEventsDashboardRoute(): boolean {
    return this.router.url.startsWith('/v2/planning/dashboard');
  }

  get isIncidentsRoute(): boolean {
    return this.router.url.startsWith('/v2/control');
  }
  get isIncidentsReportingPreviewRoute(): boolean {
    return this.router.url.startsWith('/v2/control/reports') && this.router.url.endsWith('/view');
  }

  get isSettingsRoute(): boolean {
    return this.router.url.startsWith('/v2/settings');
  }

  get isRunsheetsRoute(): boolean {
    return this.router.url.startsWith('/v2/runsheet');
  }

  get isReportingRoute(): boolean {
    return this.router.url.indexOf('reports') !== -1;
  }

  get isReportingPreviewRoute(): boolean {
    return this.isReportingRoute && this.router.url.endsWith('view');
  }
  get isPlanningRoute() {
    return this.router.url.startsWith('/v2/planning');
  }

  get isMyTrackRoute() {
    return this.router.url.startsWith('/v2/mytrack');
  }

  get isPaddingless(): boolean {
    return (
      this.isGanttRoute ||
      this.isIncidentsRoute ||
      this.isSettingsRoute ||
      this.isRunsheetsRoute ||
      this.isEventsDashboardRoute ||
      this.isPlanningRoute ||
      this.isReportingRoute ||
      this.isMyTrackRoute
    );
  }

  private initWindowEventHandler(event: string) {
    const indexableWindowEventsEmitter = this.windowEventsEmitter as {
      [key: string]: (e: Event) => void;
    };

    const targetElement = event !== 'scroll' ? window : this.routeContainer.nativeElement;

    fromEvent(targetElement, event).subscribe((e: Event) => {
      indexableWindowEventsEmitter[`broadcastWindow${capitalize(event)}EventTriggered`](e);
    });
  }

  private initWindowEventHandlers() {
    const eventsToListenTo = ['click', 'resize'];
    eventsToListenTo.forEach((event) => this.initWindowEventHandler(event));
    fromEvent(window, 'scroll', {capture: true} as EventListenerOptions).subscribe((e: Event) => {
      this.windowEventsEmitter.broadcastWindowScrollEventTriggered(e);
    });
  }

  private initTitleChangeUponNavigation() {
    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        map((event) => event as RoutesRecognized),
        map((event) => event.state.root.firstChild),
        map((activatedRouteSnapshot) => {
          while (activatedRouteSnapshot.firstChild) {
            activatedRouteSnapshot = activatedRouteSnapshot.firstChild;
          }

          return activatedRouteSnapshot;
        }),
        map((activatedRouteSnapshot) => activatedRouteSnapshot.data)
      )
      .subscribe((data) => {
        this.setProperitesFromRoutingData(data);
      });
  }

  private setProperitesFromRoutingData(event: Data) {
    // Set Title if available, otherwise leave the default Title
    const title = event['title'] ? `${event['title'] as string} - ${this.endPageTitle}` : `${this.defaultPageTitle} - ${this.endPageTitle}`;

    this.title.setTitle(this.localisationService.localise(title));

    const metaData = event['meta'] as [] || [];
    for (let i = 0; i < metaData.length; i++) {
      this.meta.updateTag(metaData[i]);
    }
  }

  private async initIntercom(employee: Employee, account: Account) {
    const { salutation, username, created } = employee;

    const employeeRoles = this.populateInteromDataWithEmployeeRoles(employee);

    const intercomData: IntercomBootInput = {
      email: username,
      name: salutation,
      user_id: `${employee.id}`,
      company: {
        company_id: account.id,
        name: account.title,
      },
      app_id: environment.intercomAppId,
      created_at: new Date(created).getTime(),
      custom_launcher_selector: '#intercom_launcher',
      ...employeeRoles,
    };

    if (this.isNativeDevice) {
      const userData = {
        customAttributes: {
          ...employeeRoles,
        },
        name: intercomData.name as string,
        userId: intercomData.user_id as string,
        email: intercomData.email,
      };
      try {
        await IntercomMobile.Intercom.registerUnidentifiedUser();
        await IntercomMobile.Intercom.updateUser(userData);
      } catch (e) {
        console.error(`ERROR INTERCOM MOBILE`, e);
      }
    } else {
      this.intercom.boot(intercomData);
    }
  }

  private populateInteromDataWithEmployeeRoles(employee: Employee): {} {
    const employeeRoleTypes = EnumUtilities.items(EmployeeRoleTypes).filter((f) => f.key >= 36);
    const obj = {};
    employeeRoleTypes.forEach((empRoleType) => {
      const key = empRoleType.value.toLowerCase();
      obj[key] = EmployeeUtil.hasRole(employee, empRoleType.key as EmployeeRoleTypes);
    });
    return obj;
  }

  private initialize() {
    this.loading = true;
    this.changeDetectorRef.detectChanges();

    this.authenticationManager.getConfiguration().subscribe((account) => {
      this.employeeSettingService.getCurrentEmployee().subscribe(async (employeeData) => {
        this.cachingService.AddEmployeeData(employeeData, account.id);
        this.currentAccount = account;
        if (!this.currentAccount.isActive) {
          void this.router.navigate(['/v2/unauthorized']);
        }
        void this.mobileVersionUpdateService.checkForUpdate();
        moment.tz.setDefault(this.timeZoneService.sanitazeTimeZone(account.accountSpecificTimeZone));
        this.currentEmployee = this.cachingService.GetEmployee(account.id);
        void this.pushNotifications.initPush(this.currentEmployee.id);
        this.pushNotifications.clearAllPushNotifications();
        await this.initIntercom(this.currentEmployee, this.currentAccount);
        this.loginEventEmitter.broadcastCurrentEmployeeChanged(this.currentEmployee);
        this.signalRService.startConnection();
        this.riskRAGMatrixService.initRAGMatrix();
        this.sortingService.refreshEmployeeCardFilterSetting();
        this.notificationsService.getNotifications();
        this.moduleService.initAccountModules();
        this.planningSettingsService.getAutoUpdates();

        // [WT-TR] to refactor when translations are ready

          this.pendoService.init(this.currentEmployee);
          const locallyStoredLangCode = this.wtStorage.getItem('langCode') ?? 'en';
          const currentLanguageCode = this.currentEmployee.languageCode ?? locallyStoredLangCode;
          this.wtTranslationService.setLanguageAndLocale(currentLanguageCode);
        forkJoin([
          this.allowedFiltersService.init().pipe(
            switchMap(() =>
            // IF onboarding is not completed show onboarding modal and then init header and filters
            // ELSE init header and filters without showing onboarding modal
              iif(
                () => !this.currentEmployee.onboarded,
                defer(() => this.initOnboardingModal()
                  .pipe(
                    switchMap(() => {
                      return this.initHeaderAndFiltersService();
                      }
                    )
                  )),
                  defer(() => this.initHeaderAndFiltersService())
                )
            )
          ),
        ]).subscribe(() => {
          this.initTitleChangeUponNavigation();

          this.loading = false;

          this.changeDetectorRef.detectChanges();
          this.initWindowEventHandlers();
          this.initIonMenuSubscriptions();

          if (this.currentAccount.useIMSOnly && this.router.url.indexOf('v2/control') < 0) {
            void this.router.navigate(['v2/control/list']);
          }
        });
      });
    });

    this.componentEventEmitter.fullPageSpaceRequested$.subscribe((state: boolean) => {
      this.maxPageRequestedByComponent = state;
      this.changeDetectorRef.detectChanges();
    });

    this.componentEventEmitter.hideHeaderRequested$.subscribe((state: boolean) => {
      this.maxHeightRequestedByComponent = state;
      this.changeDetectorRef.detectChanges();
    });
  }

  initHeaderAndFiltersService() {
    return forkJoin([this.headerFiltersCachingService.init(), this.fixedHeaderFiltersService.init()])
  }

  logout(input: boolean) {
    if (input) {
      this.currentEmployee = null;
      this.currentAccount = null;
    }
  }

  private initOnboardingModal() {
      this.loading = false;
      this.changeDetectorRef.detectChanges();
      this.bsModalRef = this.modalService.show(OnboardingModalComponent, {class: 'modal-full-screen'});
      return this.bsModalRef.onHide.pipe(take(1));
  }

  /**
   * The problem: On iOS if the input fields text is less than 16px, when focusing the input that triggers auto zoom.
   *
   * So when using field we should hook the css with "font-size: var(--input-field-font-size)", the variable will be with 14px default font size.
   * On iOS this method will set the variable to be 16px;
   */
  setIosInputFieldFontSize() {
    const iOSArr = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];
    if (
      iOSArr.includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    ) {
      document.documentElement.style.setProperty('--input-field-font-size', '16px');
    }
  }

  expandSidebar() {
    this.utilitiesService.broadcastSidebarToggle(true);
  }
  collapseSidebar() {
    this.utilitiesService.broadcastSidebarToggle(false);
  }

  listenMobileDeviceInternetStatusChange() {
    void Network.addListener('networkStatusChange',  (status) => {
      if (status.connected) {
        // Network.removeAllListeners();
        // this.ngOnInit();
        this.hideConnectivityAlert();
      } else {
        void this.showConnectivityAlert();
      }
    });
  }

  async showConnectivityAlert() {
    this.mobileConnectionAlert = await this.alertCtrl.create({
      header: 'Internet Connectivity',
      message: 'You must have active internet connection to access the application',
      backdropDismiss: false,
      cssClass: 'alert-mobile-status',
    });
    await this.mobileConnectionAlert.present();
  }

  hideConnectivityAlert() {
    if (this.mobileConnectionAlert) {
      void this.mobileConnectionAlert.dismiss();
    }
  }

  toggleSidebar() {
    this.utilitiesService.broadcastSidebarToggle(!this.isSidebarToggled);
  }

  get isMobile(): boolean {
    return this.userAgentService.isXSmall;
  }

}
