import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export enum ModalHeaderTheme {
  Light,
  Dark,
}

export enum ModalHeaderIconType {
  Material,
  Rich,
  Custom,
}

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent {
  @Input() title: string;
  @Input() iconType = ModalHeaderIconType.Material;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() iconTransform: string;
  @Input() richIconType: string;
  @Input() customIconSrc: string;
  @Input() showDelete = false;
  @Input() showFullScreenIcon = false;
  @Input() editableTitle = false;
  @Input() fontSize: number = 20;
  @Input() lineClamp: number = 3;

  @Output() closed: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() fullScreenRequested: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() titleUpdate: EventEmitter<string> = new EventEmitter<string>();

  modalHeaderTheme = ModalHeaderTheme;
  modalHeaderIconType = ModalHeaderIconType;

  onClose(e: Event) {
    this.closed.emit(e);
  }

  onDelete(e: Event) {
    this.deleted.next();
  }

  onFullScreen(e: Event) {
    this.fullScreenRequested.next();
  }

  onTitleUpdate(newTitle: string){
    this.titleUpdate.emit(newTitle);
  }
}
