<ng-template #firstDateEditMode>
  <div class="details-layout-heading">{{ firstDateLabel }}</div>
  <ng-container>
    <div class="d-flex">
      <div class="date-wrapper">
        <app-datepicker
          [placeholder]="(T.defaultLocalizations.date.one | translate) + '*'"
          [required]="true"
          [date]="firstDateString"
          (dateChanged)="onStartDateChanged($event)"
        >
        </app-datepicker>
      </div>
      <div class="time-picker-position">
        <app-timepicker [date]="firstDateString" (timeChanged)="onStartTimeChanged($event)"></app-timepicker>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #firstDateViewMode>
  <div class="details-layout-heading">{{ firstDateLabel }}</div>
  <ng-container>
    <div class="details-layout-description">{{ localisedStartDate }}</div>
  </ng-container>
</ng-template>
<ng-template #secondDateEditMode>
  <div class="details-layout-heading pt-1">{{ secondDateLabel }}</div>
  <ng-container>
    <div class="d-flex">
      <div class="date-wrapper">
        <app-datepicker
          [placeholder]="(T.defaultLocalizations.date.one | translate) + '*'"
          [required]="true"
          [date]="secondDateString"
          [minDate]="minDate"
          (dateChanged)="onEndDateChanged($event)"
        >
        </app-datepicker>
      </div>
      <div class="time-picker-position">
        <app-timepicker
          [date]="secondDateString"
          [minDate]="minDate"
          (timeChanged)="onEndTimeChanged($event)"
        ></app-timepicker>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #secondDateViewMode>
  <div class="details-layout-heading">{{ secondDateLabel }}</div>
  <ng-container *ngIf="!editMode">
    <div class="details-layout-description">{{ localisedEndDate }}</div>
  </ng-container>
</ng-template>

<div class="row" *ngIf="editMode">
  <div class="col-12">
    <div class="checkbox-wrapper">
      <app-checkbox [isChecked]="showPickers" (checked)="toggleShowPickers($event)"> </app-checkbox>
      <span>
        {{ T.calendar.set_start_and_end_time | translate: { start: firstDateLabel, end: secondDateLabel } }}
      </span>
    </div>
  </div>
</div>
<ng-container *ngIf="!isDetailsPage">
  <div class="row form-group" *ngIf="!editMode || (editMode && showPickers)">
    <div class="col-12" *ngIf="!editMode">
      <ng-container *ngTemplateOutlet="firstDateViewMode"></ng-container>
    </div>
    <div class="col-12" *ngIf="editMode && showPickers">
      <ng-container *ngTemplateOutlet="firstDateEditMode"></ng-container>
    </div>
  </div>
  <div class="row form-group" *ngIf="!editMode || (editMode && showPickers)">
    <div class="col-12" *ngIf="!editMode">
      <ng-container *ngTemplateOutlet="secondDateViewMode"></ng-container>
    </div>
    <div class="col-12" *ngIf="editMode && showPickers">
      <ng-container *ngTemplateOutlet="secondDateEditMode"></ng-container>
    </div>
    <div *ngIf="showDateError">
      <span class="text-error">
        {{
          T.calendar.endLabel_time_should_be_after_startLabel_time
            | translate: { endLabel: secondDateLabel, startLabel: firstDateLabel }
        }}
      </span>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isDetailsPage">
  <div class="row form-group" *ngIf="!editMode || (editMode && showPickers)">
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12" *ngIf="!editMode">
          <ng-container *ngTemplateOutlet="firstDateViewMode"></ng-container>
        </div>
        <div class="col-12" *ngIf="editMode && showPickers">
          <ng-container *ngTemplateOutlet="firstDateEditMode"></ng-container>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12" *ngIf="!editMode">
          <ng-container *ngTemplateOutlet="secondDateViewMode"></ng-container>
        </div>
        <div class="col-12" *ngIf="editMode && showPickers">
          <ng-container *ngTemplateOutlet="secondDateEditMode"></ng-container>
          <div *ngIf="showDateError">
            <span class="text-error">
              {{
                T.calendar.endLabel_time_should_be_after_startLabel_time
                  | translate: { endLabel: secondDateLabel, startLabel: firstDateLabel }
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
