import { EventTypeListItemViewModel } from './eventCategoryListItemViewModel';
import { DashboardInfoWidgetViewModel } from 'src/app/modules/settings/viewModels/dashboardInfoWidgetViewModel';
import { FilterViewModel } from '../../models/filter/filterViewModel';
import { TranslateService } from '@ngx-translate/core';

export class EventDetailsViewModel {
  public id: number;
  public title: string;
  public startDate: string;
  public endDate: string;
  public accountId: number;
  public dashboardWidgetInfo: DashboardInfoWidgetViewModel;
  public categories: EventTypeListItemViewModel[];
  public filters: FilterViewModel[];
  permissionFilters: FilterViewModel[];
  public capacity: number;
  constructor(_accountId: number, translateService: TranslateService) {
    this.accountId = _accountId;
    this.title = '';
    this.startDate = new Date().toISOString();
    this.endDate = new Date().toISOString();
    this.categories = [];
    this.dashboardWidgetInfo = new DashboardInfoWidgetViewModel(translateService);
    this.filters = [];
    this.capacity = 0;
  }
}
