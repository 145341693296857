import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  TemplateRef,
  OnInit,
  OnChanges,
} from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';
import { RAGBreakdown } from '../../../models/rag/ragBreakdown';
import { RAGStatuses } from '../../../enums/ragStatuses';
import { IconUtilities } from '../../../utilities/icon.utilities';
import { RagHelper } from '../../../utilities/rag.utilities';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterTypeLozengeLabelUtillities } from '../../../utilities/filterType-Lozenge-Label.utilities';
import { Constants } from 'src/app/modules/shared/models/constants';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { EventDetailsViewModel } from '../../../viewModels/events/eventDetailsViewModel';
import { NgBlockScreenDirective } from '../../../directives/ngBlockScreen.directive';
import { EqualityUtilities } from '../../../utilities/equality.utilities';
import { TimeZoneService } from '../../../services/timeZone.service';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { MilestoneTypes } from '../../../enums/milestoneTypes';
import { LocalisationService } from '../../../services/localisation.service';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import {
  NgRangeDatesOutput,
  NgRangePickerActions,
  NgRangeSelectCallback,
} from '../../../models/rangeDatepicker/rangeDatepickerModels.model';
import { PopupEventsEmitter } from '../../../events/popup.events';
import { fromEvent, of } from 'rxjs';
import { takeUntil, delay, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { RiskActionStatuses } from 'src/app/modules/risk/enums/riskActionStatusType.enum';

enum LozengeTypes {
  Date,
  Time,
  Task_Breakdown,
  Regular,
  Risk_Breakdown,
  Issue_Breakdown,
  Opportunity_Breakdown,
  Range_Time,
}

@Component({
  selector: 'app-card-lozenge',
  templateUrl: './card-lozenge.component.html',
  styleUrls: ['./card-lozenge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardLozengeComponent implements OnInit, OnChanges {
  @ViewChild('cardLozenge') cardLozengeElementRef: ElementRef<HTMLElement>;
  @ViewChild('rangeItemLozenge') rangeItemLozenge: ElementRef<HTMLElement>;
  @ViewChild('inlineEditContainer') inlineEditContainer: TemplateRef<HTMLElement>;
  @ViewChild(NgBlockScreenDirective) blockScreenDirective: NgBlockScreenDirective;
  //Input
  allowedEmployeeFilters: FilterViewModel[];
  @Input() filterTypeSelector: FilterTypeSelectorViewModel;
  @Input() filters: FilterViewModel[];
  @Input() editMode: boolean;
  @Input() exclude: boolean;
  @Input() canEdit: boolean = false;
  @Input() allAppliedFilters: FilterViewModel[];
  @Input() objectType: ObjectTypes;
  @Input() comparatorDate: string;

  // Output
  @Output() riskRAGBreakdownClicked = new EventEmitter<FilterViewModel>();
  @Output() taskRAGBreakdownClicked = new EventEmitter<FilterViewModel>();
  @Output() lozengeUpdates= new EventEmitter<FilterViewModel[]> ();
  @Output() lozengeClicked= new EventEmitter<FilterTypes> (); //For handling a lozenge click externally
  @Output() lozengeHover = new EventEmitter<FilterTypes>(); //For handling a lozenge click externally

  // public
  readonly filterTypes = FilterTypes;
  readonly filterSelectorTypes = FilterSelectorTypes;
  readonly objectTypes = ObjectTypes;

  public selectedFilterSelectorType: FilterSelectorTypes;
  initPerformed = false;
  public events: EventDetailsViewModel[] = [];
  ragBreakdownTaskName: string;
  ragBreakdownRioName: string;
  filterType: FilterTypes;
  iconByType: string;
  filterText: string;
  filterLabel: string;
  overflowingCount: number;
  emptyState: boolean;
  ragBreakdownValue: number;
  ragBreakdownBars: RAGBreakdown[];
  ragBreakdownText: string;
  ragBreakdownTotal: number;
  isLabelLozenge: boolean;
  isIconLozenge: boolean;
  isSvgIcon: boolean;
  hasLabelWithIcon: boolean;
  handleLozengeClickExternally: boolean = false;
  svgIconFilterTypes = [
    FilterTypes.Task_Group,
    FilterTypes.Risk_Breakdown,
    FilterTypes.Issue_Breakdown,
    FilterTypes.Opportunity_Breakdown,
    FilterTypes.Risk_Action_Breakdown,
  ];
  isMilestoneFilterType: boolean;
  isDateAndHasMilestone: boolean;
  isRunsheetItemDependenciesCountFilterType: boolean;
  public lozengeType: LozengeTypes;
  public readonly lozengeTypes = LozengeTypes;
  public readonly notEditableFilterTypes: FilterTypes[] = [
    FilterTypes.Ref_Code,
    FilterTypes.Risk,
    FilterTypes.Milestone_Type,
    FilterTypes.Task_Group,
    FilterTypes.Dependent_Task,
    FilterTypes.Financial_Impact,
    FilterTypes.Risk_Breakdown,
    FilterTypes.Issue_Breakdown,
    FilterTypes.Opportunity_Breakdown,
    FilterTypes.Risk_Action_Breakdown,

    // Likelihood is handled externally by the rag matrix modal
    FilterTypes.Risk_Likelihood,
    // Impact is handled externally by the rag matrix modal
    FilterTypes.Risk_Impact,
  ];

  public readonly iconWithLabelLozenges: FilterTypes[] = [
    FilterTypes.Risk_Breakdown,
    FilterTypes.Opportunity_Breakdown,
    FilterTypes.Issue_Breakdown,
  ];

  public isNotEditable: boolean = false;
  public isRangeDate: boolean;
  public readonly rangeDateObjects: ObjectTypes[] = [ObjectTypes.Task, ObjectTypes.Job];
  public startDate: string | Date;
  public endDate: string | Date;
  public singleDate: string | Date;
  public isSingleSelect: boolean;
  public filterTextStyles: object;
  // This will make it easier to unite task Breakdown with Risks one in the house keeping
  public isBreakdownLozenge: boolean;
  public breakdownFilterTypes: FilterTypes[] = [
    FilterTypes.Risk_Breakdown,
    FilterTypes.Issue_Breakdown,
    FilterTypes.Opportunity_Breakdown,
    FilterTypes.Task_Breakdown,
  ];
  milestoneType: any;
  grabBreakdownText: string;
  public disableLabel: boolean = false;
  public dateFiltersAreInEmptyState: boolean = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private timeZoneService: TimeZoneService,
    private readonly localisationService: LocalisationService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly popupEvents: PopupEventsEmitter,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.filterTypeSelector) {
      this.allowedEmployeeFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(this.filterTypeSelector.filterType);
    }

    this.populate();
    this.initProperties();
    this.initPerformed = true;
    this.isRangeDate = this.getIsRangeDate();
  }

  ngOnChanges() {
    this.overflowingCount = this.getOverflowingCount();
    if (this.initPerformed) {
      this.populate();
    }
  }

  populate() {
    // This will make it easier to unite task Breakdown with Risks one in the house keeping
    this.isBreakdownLozenge = this.breakdownFilterTypes.some((ft) => ft === this.filters[0]?.filterType);
    if (this.filters && this.filters.length) {
      if (!this.isBreakdownLozenge && this.allowedEmployeeFilters) {
        this.filters.forEach((fs) => {
          const allowed = this.allowedEmployeeFilters.find(
            (a) => a.filterType === fs.filterType && a.filterValue == fs.filterValue as string
          );

          if (allowed) {
            fs.filterText = allowed.filterText;
          }
        });
      }

      this.ragBreakdownTaskName =
        this.objectType === ObjectTypes.Task
          ? this.localisationService.localiseSubTask()
          : this.localisationService.localiseObjectType(ObjectTypes.Task);

      this.dateFiltersAreInEmptyState = this.filters[0]?.filterValue as string === '' || this.filters[0]?.filterValue as number === -1;
      this.filterType = this.filters && this.filters[0] ? this.filters[0].filterType : this.filterTypeSelector.filterType;
      this.iconByType = IconUtilities.getIconForFilterType(this.filterType);
      this.filterText = this.getFilterText();
      this.filterLabel = this.getFilterLabel();
      this.handleLozengeClickExternally = this.getHandleLozengeClickEventExternally();
      this.overflowingCount = this.getOverflowingCount();
      this.selectedFilterSelectorType = this.filters[0].filterSelectorType;
      this.isSvgIcon = this.svgIconFilterTypes.some((ft) => ft === this.filterType);
      this.isMilestoneFilterType = this.filterType === FilterTypes.Milestone_Type;
      this.isRunsheetItemDependenciesCountFilterType = this.filterType === FilterTypes.Runsheet_Item_Dependencies_Count;
      this.isDateAndHasMilestone =
        this.objectType === ObjectTypes.Task &&
        this.filterType === FilterTypes.Date &&
        this.allAppliedFilters.some(
          (f) => f.filterType === FilterTypes.Milestone_Type && f.filterValue > MilestoneTypes.No_Milestone
        );

      if (
        this.filterType === this.filterTypes.Risk_Breakdown ||
        this.filterType === this.filterTypes.Issue_Breakdown ||
        this.filterType === this.filterTypes.Opportunity_Breakdown ||
        this.filterType === this.filterTypes.Task_Breakdown
      ) {
        this.ragBreakdownValue = this.setRagBreakdownValue();
        this.ragBreakdownBars = this.setRagBreakdownBars();
        this.ragBreakdownText = this.setRiskRagBreakdownText();
        this.ragBreakdownTotal = this.getRagBreakdownTotal(this.ragBreakdownBars);
        this.setGrabBreakdownText();
      }

      if (this.isSvgIcon) {
        let color = undefined;

        if (
          this.filterType === FilterTypes.Risk_Breakdown ||
          this.filterType === FilterTypes.Issue_Breakdown ||
          this.filterType === FilterTypes.Opportunity_Breakdown
        ) {
          color = this.ragBreakdownBars.reduce(
            (prev, curr) => {
              if (curr.count !== 0) {
                prev = curr;
              }
              return prev;
            },
            { colour: '#939699', count: 0 } as RAGBreakdown
          ).colour;
        }

        this.iconByType = IconUtilities.getSvgForFilterType(this.filterType, 16, false, color as string);
      }

      this.emptyState = this.setEmptyState();
      this.initDates();
      this.initFilterTextStyles();
      this.isSingleSelect = FilterUtilities.IsSingleSelectFilter(this.objectType, this.filterType);
      this.milestoneType = this.getMilestoneType();
      this.changeDetectorRef.markForCheck();
    }
  }

  initProperties() {
    const iconLozenges: FilterTypes[] = [
      FilterTypes.Milestone_Type,
      FilterTypes.Department,
      FilterTypes.Owner,
      FilterTypes.Department_Category,
      FilterTypes.Incident_Category,
      FilterTypes.Project,
      FilterTypes.Task_Group,
      FilterTypes.Risk_Breakdown,
      FilterTypes.Issue_Breakdown,
      FilterTypes.Opportunity_Breakdown,
      FilterTypes.Risk_Action_Breakdown,
      FilterTypes.Zone,
      FilterTypes.Area,
      FilterTypes.Runsheet_Item_Dependencies_Count,
      FilterTypes.Indicator_Category,
      FilterTypes.User_Group,
      FilterTypes.Job_Category,
      FilterTypes.Job_Status,
      FilterTypes.Job_Type,
    ];

    this.isIconLozenge = iconLozenges.findIndex((a) => a === this.filterType) > -1;
    this.hasLabelWithIcon = this.iconWithLabelLozenges.some((ft) => ft === this.filterType);
    this.isNotEditable = this.getIsNotEditableLozenge();

    if (this.filterType === FilterTypes.Task_Breakdown) {
      this.lozengeType = LozengeTypes.Task_Breakdown;
    } else if (this.filterType === FilterTypes.Risk_Breakdown) {
      this.ragBreakdownRioName = this.localisationService.localiseObjectType(ObjectTypes.Risk, true);
      this.lozengeType = LozengeTypes.Risk_Breakdown;
    } else if (this.filterType === FilterTypes.Issue_Breakdown) {
      this.ragBreakdownRioName = this.translateService.instant(T.defaultLocalizations.issue.many);
      this.lozengeType = LozengeTypes.Issue_Breakdown;
    } else if (this.filterType === FilterTypes.Opportunity_Breakdown) {
      this.ragBreakdownRioName = this.translateService.instant(T.defaultLocalizations.opportunity.many);
      this.lozengeType = LozengeTypes.Opportunity_Breakdown;
    }  else if (this.filterType === FilterTypes.Date) {
      if (this.objectType === ObjectTypes.Runsheet_Item) {
        this.lozengeType = LozengeTypes.Range_Time;
      } else {
        this.lozengeType = LozengeTypes.Date;
      }
    } else {
      this.lozengeType = LozengeTypes.Regular;
    }

    this.isLabelLozenge = !this.isIconLozenge && this.filterType !== FilterTypes.Task_Breakdown;
  }

  getIsNotEditableLozenge() {
    if (
      this.notEditableFilterTypes.some((ft) => ft === this.filterType) ||
      this.getNotEditableLozengesBasedOnObjType(this.objectType, this.filterType, this.filters[0]?.dateProperty)
    ) {
      return true;
    }
    return false;
  }

  private getNotEditableLozengesBasedOnObjType(
    objectType: ObjectTypes,
    filterType: FilterTypes,
    dateProperty: FilterDateOptions = 0
  ): boolean {
    switch (objectType) {
      case ObjectTypes.Project: {
        return (
          filterType === FilterTypes.Date &&
          (dateProperty === FilterDateOptions.Due_Date || dateProperty === FilterDateOptions.Start_Date)
        );
      }
      case ObjectTypes.Task: {
        return filterType === FilterTypes.Project || filterType === FilterTypes.Task_Group;
      }
      case ObjectTypes.Runsheet_Item: {
        return filterType === FilterTypes.Zone || filterType === FilterTypes.Area;
      }
      case ObjectTypes.Runsheet: {
        return filterType === FilterTypes.Date;
      }
      default: {
        return false;
      }
    }
  }

  private getHandleLozengeClickEventExternally() {
    switch (this.objectType) {
      case ObjectTypes.Task: {
        return this.filterType === FilterTypes.Project || this.filterType === FilterTypes.Task_Group;
      }
      case ObjectTypes.Risk: {
        return this.filterType === FilterTypes.Risk_Likelihood || this.filterType === FilterTypes.Risk_Impact;
      }
      default: {
        return false;
      }
    }
  }

  getMilestoneType() {
    const milestoneFilter = this.allAppliedFilters.find((f) => f.filterType === FilterTypes.Milestone_Type);
    return milestoneFilter && (this.isMilestoneFilterType || this.isDateAndHasMilestone) ? +milestoneFilter.filterValue : 0;
  }

  getFilterLabel(): string {
    const filter = this.filters[0];
    const ftype = filter.filterType;
    const label = FilterTypeLozengeLabelUtillities.getLabel(
      ftype,
      filter.dateProperty,
      this.translateService,
      this.localisationService
    );
    return this.localisationService.localise(label);
  }

  getFilterText(): string {
    if (this.filterType === FilterTypes.Department) {
      this.filters.sort((a, b) => Number(b.isPrimary) - Number(a.isPrimary));
    }

    this.filters = FilterUtilities.setFilterText(this.filters, this.localisationService, this.translateService);

    let text = this.filters.map((f) => (f.filterText ? f.filterText : '')).join(', ');

    if (this.filters[0].filterType === FilterTypes.Date && !this.dateFiltersAreInEmptyState) {
      text = this.timeZoneService.localiseDateISOString(this.filters[0].filterValue as string, false, false);
    } else if (this.isBreakdownLozenge) {
      const parsedRags = JSON.parse(this.filters[0].filterText) as RAGBreakdown[];
      const total = this.getRagBreakdownTotal(parsedRags);
      return `${total}`;
    }
    this.disableLabel = !text.length;

    const emptyValueMessage = this.emptyValueMessage()
      ? this.translateService.instant(T.common.no_item, { item: this.emptyValueMessage() })
      : this.translateService.instant(T.common.none);
    return text.length ? text : emptyValueMessage;
  }

  private emptyValueMessage(): string {
    switch (this.filterType) {
      case FilterTypes.Date:
        return this.localisationService.localiseFilterType(FilterTypes.Date);
      case FilterTypes.Zone:
        return this.localisationService.localiseFilterType(FilterTypes.Zone);
      case FilterTypes.Area:
        return this.localisationService.localiseFilterType(FilterTypes.Area);
      case FilterTypes.Owner:
        return this.localisationService.localiseFilterType(FilterTypes.Owner);
      case FilterTypes.Event:
        return this.localisationService.localiseFilterType(FilterTypes.Event);
      case FilterTypes.Project:
        return this.localisationService.localiseFilterType(FilterTypes.Project);
      case FilterTypes.Department:
        return this.localisationService.localiseFilterType(FilterTypes.Department);
      case FilterTypes.Incident_Channel:
        return this.localisationService.localiseFilterType(FilterTypes.Incident_Channel);
      case FilterTypes.Incident_Category:
        return this.localisationService.localiseFilterType(FilterTypes.Incident_Category);
      case FilterTypes.Indicator_Priority:
        return this.localisationService.localiseFilterType(FilterTypes.Indicator_Priority);
      case FilterTypes.UNSDG:
        return this.localisationService.localiseFilterType(FilterTypes.UNSDG);
      case FilterTypes.User_Group:
        return this.localisationService.localiseFilterType(FilterTypes.User_Group);
      case FilterTypes.Job_Category:
        return this.localisationService.localiseFilterType(FilterTypes.Job_Category);
      case FilterTypes.Job_Status:
        return this.localisationService.localiseFilterType(FilterTypes.Job_Status);
      case FilterTypes.Job_Type:
        return this.localisationService.localiseFilterType(FilterTypes.Job_Type);
      default:
        return null;
    }
  }
  getSingleFilterText(filter: FilterViewModel) {
    let text = filter.filterText;
    if (filter.filterType === FilterTypes.Date && filter.filterValue) {
      text = this.timeZoneService.localiseDateISOString(filter.filterValue as string, false, false);
    } else if (this.isBreakdownLozenge) {
      const parsedRags = JSON.parse(this.filters[0].filterText) as RAGBreakdown[];
      text = this.getRagBreakdownTotal(parsedRags).toString();
    }

    return text || this.translateService.instant(T.common.none).toLowerCase();
  }

  getOverflowingCount(): number {
    return this.filters.length - 1;
  }

  // ##### Rag Brakdown
  setRagBreakdownValue(): number {
    return +this.filters[0].filterValue;
  }

  setRagBreakdownBars(): RAGBreakdown[] {
    if (this.filters[0].filterText) {
      try {
        return JSON.parse(this.filters[0].filterText) as RAGBreakdown[];
      } catch (error) {
        return [];
      }
    }
    return [];
  }

  setRagBreakdownText(): string {
    const localisedTasks = this.localisationService.localiseObjectType(ObjectTypes.Task, true);
    return this.translateService.instant(T.common.view_all_items_with_count, {
      item: localisedTasks,
      count: this.ragBreakdownTotal,
    });
  }

  setRiskRagBreakdownText(): string {
    if (this.filterType === this.filterTypes.Risk_Breakdown) {
      const localisedRisks = this.translateService.instant(T.defaultLocalizations.risk.many);
      return this.translateService.instant(T.common.view_all_items_with_count, {
        item: localisedRisks,
        count: this.ragBreakdownTotal,
      });
    } else if (this.filterType === this.filterTypes.Issue_Breakdown) {
      const localisedRisks = this.translateService.instant(T.defaultLocalizations.issue.many);
      return this.translateService.instant(T.common.view_all_items_with_count, {
        item: localisedRisks,
        count: this.ragBreakdownTotal,
      });
    } else if (this.filterType === this.filterTypes.Opportunity_Breakdown) {
      const localisedRisks = this.translateService.instant(T.defaultLocalizations.opportunity.many);
      return this.translateService.instant(T.common.view_all_items_with_count, {
        item: localisedRisks,
        count: this.ragBreakdownTotal,
      });
    }
  }

  setGrabBreakdownText() {
    this.grabBreakdownText = this.translateService.instant(T.common.view_details);
  }

  getRagBreakdownTotal(ragBreakdowns: RAGBreakdown[]): number {
    let total: number = 0;
    ragBreakdowns.forEach((r) => {
      total += r.count;
    });
    return total;
  }

  setEmptyState(): boolean {
    let flag = false;

    switch (this.filterType) {
      case FilterTypes.Task_Breakdown:
        flag = this.ragBreakdownTotal === 0;
        break;

      case FilterTypes.Owner:
      case FilterTypes.Department:
      case FilterTypes.Department_Category:
        flag = this.filters.map((f) => f.filterText).join(', ').length === 0;
        break;

      case FilterTypes.Project:
      case FilterTypes.Event:
      case FilterTypes.Project_Status:
      case FilterTypes.Project_Privacy_Status:
      case FilterTypes.Zone:
      case FilterTypes.Area:
      case FilterTypes.Indicator_Priority:
      case FilterTypes.UNSDG:
      case FilterTypes.Milestone_Type:
      case FilterTypes.User_Group:
      case FilterTypes.Job_Category:
      case FilterTypes.Job_Status:
      case FilterTypes.Job_Priority:
      case FilterTypes.Incident_Category:
        flag = !this.filters[0].filterText;
        break;

      case FilterTypes.Date:
        flag = this.dateFiltersAreInEmptyState;
        break;
      default:
        flag = false;
        break;
    }

    return flag;
  }
  //#region Rag Brakdown

  getRagBreakdownSquareClass(r: RAGBreakdown) {
    return `rag-square ${RagHelper.getRagBoxFromValue(r.ragStatus as RAGStatuses)}`;
  }


  getRagBreakdownCountSummary(r: RAGBreakdown) {
    const showMany = r.count === 0 || r.count > 1;
    const name =
      this.objectType === ObjectTypes.Task
        ? this.localisationService.localiseSubTask(showMany)
        : this.localisationService.localiseObjectType(ObjectTypes.Task, showMany);
    return `${r.count} ${name}`;
  }

  getRiskRagBreakdownCountSummary(r: RAGBreakdown) {
    return `${r.count} ${this.ragBreakdownRioName}`;
  }


  taskRagLinkClicked(r: RAGBreakdown) {
    if (r === null) {
      this.taskRAGBreakdownClicked.emit(null);
    } else {
      const ragFilter: FilterViewModel = new FilterViewModel();
      ragFilter.id = r.ragStatus;
      ragFilter.filterType = FilterTypes.RAG;
      ragFilter.filterText = 'Task RAG';
      ragFilter.filterValue = r.ragStatus;
      ragFilter.displayForGlobalObjectType = ObjectTypes.Task;
      this.taskRAGBreakdownClicked.emit(ragFilter);
    }
  }

  riskRagLinkClicked(r: RAGBreakdown) {
    if (r === null) {
      this.riskRAGBreakdownClicked.emit(null);
    } else {
      const ragFilter: FilterViewModel = new FilterViewModel();
      ragFilter.id = r.ragStatus;
      ragFilter.filterType = FilterTypes.Risk_RAG;
      ragFilter.filterText = 'Risk RAG';
      ragFilter.filterValue = r.ragStatus;
      ragFilter.displayForGlobalObjectType = ObjectTypes.Risk;
      this.riskRAGBreakdownClicked.emit(ragFilter);
    }
  }


  //#endregion

  get mobile(): boolean {
    return window.innerWidth <= Constants.xs;
  }

  onBlockScreenClicked(): void {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    this.editMode = undefined;
  }

  onLozengeClicked(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    if (this.filterType === FilterTypes.Runsheet_Item_Dependencies_Count) {
      this.onLozengeClickedExternal(e);
    }

    this.closePopup();

    const nonEditFilterTypes = [FilterTypes.Task_Breakdown, FilterTypes.Department_Category];
    if (nonEditFilterTypes.findIndex((ft) => ft === this.filterType) > -1) {
      return;
    }

    if (this.selectedFilterSelectorType && this.selectedFilterSelectorType > 0) {
      this.editMode = this.editMode === undefined ? false : !this.editMode;

      if (this.editMode) {
        document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
      } else {
        this.updateBlockScreen(this.cardLozengeElementRef);
      }
    }
  }

  closePopup() {
    this.popupEvents.broadcastPopupCancelClicked();
  }

  onLozengeClickedExternal(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.lozengeClicked.next(this.filterType);
  }

  onLozengeHoveredExternal() {
    const mouseenter$ = fromEvent(this.rangeItemLozenge.nativeElement, 'mouseenter');
    const mouseleave$ = fromEvent(this.rangeItemLozenge.nativeElement, 'mouseleave');

    mouseenter$
      .pipe(mergeMap((event) => of(event).pipe(delay(1000), takeUntil(mouseleave$))))
      .subscribe(() => this.lozengeHover.next(this.filterType));
  }

  updateFilters(filters: FilterViewModel[]): void {

    const existingFilters = this.filters.filter((f) => f.filterType === this.filterType);
    if (!EqualityUtilities.arraysEqual(existingFilters, filters)) {
      const otherFilters = this.filters.filter((f) => f.filterType !== this.filterType);

      if (filters && filters.length) {
        otherFilters.push(...filters);
      }

      this.lozengeUpdates.next(otherFilters);
    }
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    this.editMode = undefined;
  }

  private updateBlockScreen(elementRef: ElementRef<HTMLElement>): void {
    this.blockScreenDirective.ngBlockScreenInstance.show();
    this.blockScreenDirective.blockScreenElementRef = elementRef;
    this.blockScreenDirective.updateInstance();
    this.blockScreenDirective.addElementRefStyles();
  }

  // Date Lozenges stuff start here

  getIsRangeDate() {
    if (this.filterType === FilterTypes.Date && this.rangeDateObjects.some((objT) => objT === this.objectType)) {
      return true;
    }
    return false;
  }

  initDates() {
    if (this.filterType === FilterTypes.Date) {
      const findedStartDate = this.allAppliedFilters.find(
        (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Date
      );
      const findedEndDate = this.allAppliedFilters.find(
        (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Due_Date
      );

      if (findedStartDate && findedStartDate.filterValue) {
        this.startDate = findedStartDate.filterValue as string;
      }
      if (findedEndDate && findedEndDate.filterValue) {
        this.endDate = findedEndDate.filterValue as string;
      }

      if (this.filters[0].filterValue) {
        this.singleDate = this.filters[0].filterValue as string;
      }
    }
  }

  onRangeDateChange(datesIsoObj: NgRangeDatesOutput) {
    const findedStartDateFilter = this.allAppliedFilters.find(
      (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Date
    );
    const findedDueDateFilter = this.allAppliedFilters.find(
      (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Due_Date
    );

    const dateFiltersArr: FilterViewModel[] = [];

    if (findedStartDateFilter) {
      const coppiedStartDateFilter = JSON.parse(JSON.stringify(findedStartDateFilter)) as FilterViewModel;
      coppiedStartDateFilter.filterValue = datesIsoObj.startDate as string;
      coppiedStartDateFilter.filterText = datesIsoObj.startDate as string;
      dateFiltersArr.push(coppiedStartDateFilter);
    } else {
      const generatedFilter = FilterUtilities.GenerateFilter(
        FilterTypes.Date,
        datesIsoObj.startDate,
        datesIsoObj.startDate as string,
        FilterDateOptions.Start_Date
      );
      dateFiltersArr.push(generatedFilter);
    }

    if (findedDueDateFilter) {
      const coppiedDueDateFilter = JSON.parse(JSON.stringify(findedDueDateFilter)) as FilterViewModel;
      coppiedDueDateFilter.filterValue = datesIsoObj.endDate as string;
      coppiedDueDateFilter.filterText = datesIsoObj.endDate as string;
      dateFiltersArr.push(coppiedDueDateFilter);
    } else {
      const generatedFilter = FilterUtilities.GenerateFilter(
        FilterTypes.Date,
        datesIsoObj.endDate,
        datesIsoObj.endDate as string,
        FilterDateOptions.Due_Date
      );
      dateFiltersArr.push(generatedFilter);
    }

    this.lozengeUpdates.next(dateFiltersArr);
  }

  onDatesSelectMilestoneCallback: NgRangeSelectCallback = (action, currentState) => {
    const coppiedState = { ...currentState };
    if (this.isDateAndHasMilestone) {
      if (action === NgRangePickerActions.StartDateChange) {
        coppiedState.localEndDate = coppiedState.localStartDate;
      } else if (action === NgRangePickerActions.EndDateChange) {
        coppiedState.localStartDate = coppiedState.localEndDate;
      }
    }
    return { ...coppiedState };
  };

  onRegularDateChange(dateAsIsoString: string) {
    const currentDateFilter = JSON.parse(JSON.stringify(this.filters[0])) as FilterViewModel;
    if (currentDateFilter) {
      currentDateFilter.filterValue = dateAsIsoString;
      currentDateFilter.filterText = dateAsIsoString;
      this.lozengeUpdates.next([currentDateFilter]);
    }
  }
  onDateRemove() {
    const currentDateFilter = JSON.parse(JSON.stringify(this.filters[0])) as FilterViewModel;
    if (currentDateFilter) {
      currentDateFilter.filterValue = -1;
      this.lozengeUpdates.next([currentDateFilter]);
    }
  }

  initFilterTextStyles() {
    const styles = {};
    if (this.filterType === FilterTypes.Risk_Action_Status) {
      styles['font-weight'] = 600;
      if (this.filters[0] && this.filters[0].filterValue === RiskActionStatuses.Complete) {
        styles['color'] = 'var(--wt-primary)';
      } else if (this.filters[0] && this.filters[0].filterValue === RiskActionStatuses.InProgress) {
        styles['color'] = 'var(--wt-green)';
      } else if (this.filters[0] && this.filters[0].filterValue === RiskActionStatuses.NotStarted) {
        styles['color'] = 'var(--wt-grey-dark)';
      } else if (this.filters[0] && this.filters[0].filterValue === RiskActionStatuses.Slipped) {
        styles['color'] = 'var(--red)';
      }
    }

    if (this.objectType === ObjectTypes.Runsheet && this.filterType === FilterTypes.Date) {
      styles['text-align'] = 'center';
    }

    this.filterTextStyles = styles;
  }

  getStartTimeISO() {
    return this.allAppliedFilters.find((f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Time)
      ?.filterValue as string;
  }

  geTEndTimeISO() {
    return this.allAppliedFilters.find((f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Due_Time)
      ?.filterValue as string;
  }

  getComparatorDate() {
    return this.comparatorDate;
  }

  getRangeTimeLock() {
    return this.allAppliedFilters.some((f) => f.filterType === FilterTypes.Dependent_Runsheet_Item && f.filterValue?.id);
  }
}
