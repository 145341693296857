export enum FilterTypes {
  Empty = 0,
  Account = 10000,

  // Main/Report filters
  Owner = 24,
  Department = 4,
  RAG = 12,
  DateRange = 15,
  Due_Date = 16,
  Venue = 3,
  Task_System = 6,
  Venue_Category = 2,
  Venue_Type = 1,
  Venue_Tag = 35,
  Department_Category = 401,
  Milestone_Type = 270,
  Venue_Manager = 34,
  Health_and_Safety = 46,
  Client_Group = 45,
  Priority = 13,
  Keyword = 14,
  Venue_Department = 402,
  Item_Type = 8,
  Task_Type = 9,
  Update_Type = 535,
  Status = 17,
  Archived = 18,
  Risk_Likelihood = 53,
  Risk_Impact = 52,
  Risk_Impact_Type = 55,
  Risk_Privacy_Status = 56,
  Risk_Strategy = 57,
  Risk_Status = 58,
  Risk_Type = 59,
  Incident_Severity = 33,
  ImsIncident_Severity = 39,
  Incident_Status = 40,
  Privacy_Status = 41,
  Incident_Resolution_Time = 47,
  Incident_Channel = 60,
  Headline_Status = 61,
  Incident_Category = 64,
  Incident_Location = 67,
  Task_Has_Attachments = 62,
  Task_Has_Dependencies = 63,
  Hide_Confidential = 65,
  Incident_Has_Attachments = 66,
  Show_Archived_Incidents = 68,
  Incident_Type = 69,
  Employee = 70,

  Tag = 36,
  Tag_Group = 44,
  Tag_Category = 43,
  Zone = 37,
  Created_By = 38,
  Identified_By = 99,

  Area = 49,
  // Extras
  Keyword_Specification = 27,
  Prepopulated_Text_Message = 28,
  Chat_Message = 29,
  Public_Incident_Report_Status = 30,
  Public_Incident_Report = 31,
  Incident = 32,
  DateRangeType = 150,
  IDsCSV = 54,
  Current_EmployeeID = 42,
  GlobalObjectID = 50,
  GlobalObjectType = 51,
  Output_SortColumn = 19,
  Output_SortAsc = 20,
  URL = 100,
  Financial_Impact = 102,
  Updated_By = 103,
  Title = 104,
  Description = 105,
  Document_Category = 106,
  What_Changed = 107,
  Report_Activity_Types = 108,
  Show_Archived = 109,
  Ref_Code = 110,
  Reported_By = 111,
  Has_Attachments = 112,
  Date = 113,
  Subscriber = 114,
  Additional_Info = 115,
  Abbreviation = 116,

  //Charts
  Chart_Object_Type = 200,
  Chart_Object = 201,
  Time_Scale = 202,

  // Filters
  Filter_Dates_By = 820,
  Date_Ranges = 821,

  // Dependent Tasks
  Dependent_Task = 910,

  Log_Types = 800,

  Action_Step_Type = 710,
  Risk_Action_Status = 711,
  Risk_Action_Type = 712,
  Risk_Action_Item = 713,
  Approver = 714,
  Risk_Score_Type = 715,

  Module = 1000,
  Import_Upload_Object = 1001,
  Import_Type = 1002,

  Start_Time = 1010,
  End_Time = 1011,

  Event = 1050,
  Event_Type = 1052,

  Task = 2000,
  Parent_Task = 2001,
  Task_Breakdown = 2002,
  Task_Priority = 2016,
  Project = 3000,
  Project_Status = 3001,
  Project_Privacy_Status = 3002,

  Task_Group = 4000,

  Risk = 5000,
  Risk_Breakdown = 5001,
  Issue_Breakdown = 5002,
  Opportunity_Breakdown = 5003,
  Risk_Action_Breakdown = 5004,
  Risk_Profile = 5009,
  Risk_Profile_Status = 5010,
  Risk_Profile_Question = 5011,
  Risk_Profile_Answer = 5012,
  Checkpoint_Type = 1060,
  Checkpoint = 1061,
  Check = 1062,
  PEAP = 1063,
  PEAP_Group = 1064,
  Resolution = 1065,
  PEAP_Group_Status = 1066,
  PEAP_Check_Status = 1067,
  External_User = 1070,
  Risk_RAG = 1080,
  Parent_Only = 1082,
  Show_Empty = 1083,

  Created = 2011,
  Updated = 2012,
  locationLongitude = 2013,
  locationLatitude = 2014,
  locationDetails = 2015,
  Risk_Assesment = 5005,
  Risk_Assesment_Status = 5006,
  Risk_Assesment_Privacy_Status = 5007,
  Is_Master_Risk = 5008,
  Indicator = 7000,
  Indicator_Category = 7001,
  Indicator_Target = 7002,
  Indicator_Target_Type = 7003,
  Indicator_Update = 7004,
  Indicator_RAG = 7005,
  Indicator_Update_Type = 7006,
  Indicator_Tracking_Date_Type = 7007,
  Indicator_Priority = 7008,
  UNSDG = 7009,
  Indicator_Target_Badge_Type = 7010,
  Indicator_Template = 7012,
  Expected_Direction = 7013,
  Sustainability_Scope = 7014,
  Pillar = 7015,
  Reporting_Directive = 7016,
  GHG_Reporting_Category = 7017,
  Unit_Type = 7024,
  Unit = 7025,
  Runsheet = 6000,
  Runsheet_Item = 6001,
  Runsheet_Item_Priority = 6002,
  Runsheet_Incomplete_Items = 6003,
  Dependent_Runsheet_Item = 6004,
  Runsheet_Item_Dependencies_Count = 6005,
  Runsheet_Item_Dependencies = 6006,
  User_Group = 71,
  Subscriber_Group = 72,
  Api_Access = 73,

  Job = 8000,
  Job_Category = 8001,
  Job_Status = 8002,
  Job_Priority = 8003,
  Job_Type = 8004,

  //Account Hub
  Hub_ObjectTypes = 9000,
  Task_Count=9001,
  Active_State =9002
}
